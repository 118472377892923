/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import './styles.scss';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../assets/img/logo-vikings.png';
import { signupService } from '../../services/auth';
import Loader from '../../components/loader';
import 'react-toastify/dist/ReactToastify.css';

export default function SignUp() {
  const [formData, setFormData] = useState({
    firstName: '',
    surName: '',
    email: '',
    password: '',
    repeatPassword: '',
    zipCode: '',
    address: '',
    phone: '',
    city: '',
    state: '',
  });

  const [errors, setErrors] = useState({});
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  function renderMsgError() {
    if (error) {
      return (
        <p className="error-txt">{`${errorMessage}.`}</p>
      );
    }
    return null;
  }

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateFields = () => {
    const newErrors = {};
    const requiredFields = ['firstName', 'surName', 'email', 'password', 'repeatPassword', 'zipCode', 'address', 'phone', 'city', 'state'];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required`;
      }
    });

    if (formData.email && !validateEmail(formData.email)) {
      newErrors.email = 'Invalid email format';
    }

    if (formData.password && formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters';
    }

    if (formData.password !== formData.repeatPassword) {
      newErrors.repeatPassword = 'Passwords do not match';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const signup = (e) => {
    e.preventDefault();

    if (!validateFields()) {
      return;
    }

    setLoading(true);
    signupService(formData.firstName, formData.surName, formData.email, formData.password,
      formData.repeatPassword,
      formData.zipCode, formData.address, formData.city, formData.phone, formData.state)
      .then(() => {
        setLoading(false);
        setFormData({
          firstName: '',
          surName: '',
          email: '',
          password: '',
          repeatPassword: '',
          zipCode: '',
          address: '',
          city: '',
          phone: '',
          state: '',
        });
        history.push('/user-created');
      })
      .catch((resp) => {
        const err = resp.response;
        setError(true);
        setLoading(false);
        if (err.data.code === 'missing_parameters') {
          setErrorMessage('Missing fields');
          toast.error('Missing fields');
        }
      });
  };

  return (
    <div className="signup-page">
      <div className="gradient-side">
        <div className="signup-cont">
          <h4>Welcome</h4>
          <p>Please enter your details to access your account</p>
        </div>
      </div>
      <div className="signup-side">
        {!loading ? (
          <>
            <form className="signup-box form-login-container" onSubmit={signup}>
              <img className="logo-vikings" src={logo} alt="" />
              <div className="brand">Sign up</div>
              {['firstName', 'surName', 'email', 'phone', 'address', 'zipCode'].map((field, index) => (
                <div className="line-form" key={index}>
                  <span>{field.toUpperCase()}</span>
                  <input
                    type={field === 'email' ? 'email' : 'text'}
                    name={field}
                    placeholder={`Enter your ${field}`}
                    value={formData[field]}
                    onChange={handleChange}
                  />
                  {errors[field] && <p className="error-txt">{errors[field]}</p>}
                </div>
              ))}
              <div className="password-container">
                {['city', 'state'].map((field, index) => (
                  <div className="span-input-password" key={index}>
                    <div className="password-label">
                      <span>{field.toUpperCase()}</span>
                    </div>
                    <div>
                      <input
                        type="text"
                        name={field}
                        placeholder={`Enter your ${field}`}
                        value={formData[field]}
                        onChange={handleChange}
                      />
                      {errors[field] && <p className="error-txt">{errors[field]}</p>}
                    </div>
                  </div>
                ))}
              </div>
              <div className="password-container">
                {['password', 'repeatPassword'].map((field, index) => (
                  <div className="span-input-password" key={index}>
                    <div className="password-label">
                      <span>{field.toUpperCase().replace('_', ' ')}</span>
                    </div>
                    <div>
                      <input
                        type="password"
                        name={field}
                        autoComplete="new-password"
                        placeholder={field === 'password' ? 'At least 6 characters' : 'Repeat your password'}
                        value={formData[field]}
                        onChange={handleChange}
                      />
                      {errors[field] && <p className="error-txt">{errors[field]}</p>}
                    </div>
                  </div>
                ))}
              </div>
              <div className="button-submit-container">
                <button type="submit" className="btn-gral">Sign up</button>
              </div>
              {renderMsgError()}
              <p className="text-gradient">
                Already have an account?
                <Link className="link" to="/login">Login</Link>
              </p>
            </form>
          </>
        ) : (
          <div className="signup-side">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}
