import React from 'react';
import './styles.scss';

export default function GalleryElement() {
  return (
    <div className="galleryCont">
      <img className="galImg" src="/assets/related1.png" alt="form" />
      <img className="galImg" src="/assets/related3.png" alt="form" />
      <img className="galImg" src="/assets/related2.png" alt="form" />
    </div>
  );
}
