import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

export default function ItemLibrary({ item }) {
  return (
    <div className="box-item-library">
      {item && item.file ? (
        <img src={`${process.env.REACT_APP_API_IMAGE_URL}${item.file?.path}`} alt="empty" />
      ) : (
        <img src="/assets/stickerempty.png" alt="empty" />
      )}
      <p>{item ? item.name : 'Sticker name'}</p>
      <button type="button" className="btn-gral btn-secondary">
        {item ? (
          <Link
            to={(location) => ({
              ...location,
              pathname: `/product-library/${item.id}`,
              state: {
                id: item.id,
                name: `${item.name}`,
                image: `${item.file?.path}`,
                price: item.price,
                width: item.width,
                height: item.height,
              },
            })}
          >
            {`Buy 1 for $${(parseFloat(item.price) / 100).toFixed(2)}`}
          </Link>
        ) : (
          'Buy 1 for $3.10'
        )}
      </button>
    </div>
  );
}
